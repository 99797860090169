import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import logo from './logo.svg';
import Device from './device';
import './App.css';


const App = () => {
    return(
        <div>
            <Device/>
        </div>
    );
}

export default App;
