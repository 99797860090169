import React, { useEffect, useRef } from "react";
import { Socket } from "socket.io-client";

interface SocketClientProps {
    socket: Socket;
}

const DisplayVideo: React.FC<SocketClientProps> = ({ socket }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
    
            if (ctx) {
                socket.on('video_stream', (data) => {
                    const img = new Image();
                    
                    // Tạo một Blob từ dữ liệu byte
                    const blob = new Blob([new Uint8Array(data)], { type: 'image/jpeg' });
                    
                    // Sử dụng FileReader để đọc Blob dưới dạng Data URL
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        img.src = reader.result as string;
                    
                        img.onload = () => {
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        };
                    };
                    
                    reader.readAsDataURL(blob);
                });
            }
        }
    }, []);

    return(<canvas ref={canvasRef} width={640} height={480}></canvas>);
}

export default DisplayVideo;