import { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import DisplayVideo from './display-video';

const Device = () => {
    // const socket = io('http://localhost:8000?userId=123&deviceId=456');
    const [qrCode, setQRCode] = useState(null);
    const [scanTime, setScanTime] = useState<string | null>(null);
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        const apiRootUrl = process.env.API_ROOT_URL || 'https://doha-api.dinoapp.vn';
        const socketIo  = io(`${apiRootUrl}?userId=123&deviceId=456`);

        // Lưu socket vào state
        setSocket(socketIo);

        // Lắng nghe sự kiện kết nối thành công
        socketIo.on('connect', () => {
            console.log('Kết nối thành công với server.');
        });
    
        // Lắng nghe sự kiện ngắt kết nối
        socketIo.on('disconnect', () => {
            console.log('Mất kết nối với server.');
        });
    
        // Xử lý sự kiện reconnect_attempt
        socketIo.on('reconnect_attempt', (attemptNumber) => {
            console.log(`Đang thử kết nối lại lần thứ ${attemptNumber}...`);
        });
    
        // Xử lý sự kiện reconnect_failed
        socketIo.on('reconnect_failed', () => {
            console.log('Kết nối lại thất bại sau nhiều lần thử.');
        });
    
        socketIo.on('response', (data) => {
            console.log(data);
            // Có thể hiển thị dữ liệu quét ở đây
            if (data.type === 'scan_qr') {
              console.log(data.data.qr_code);
              setQRCode(data.data.qr_code);

              const date = new Date(data.data.scan_time * 1000);
              console.log(data.data.scan_time);
              setScanTime(date.toLocaleString());
            }
        });

        return () => {
            socketIo.disconnect();
        };
      }, []);

    return (
        <div>
            <p>QR Code: {qrCode}</p>
            <p>Scan time: {scanTime}</p>
            {socket && <DisplayVideo socket={socket}></DisplayVideo>}
        </div>
    );
}

export default Device;
